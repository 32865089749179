<template>
  <v-system-bar
    dark
    color="#0043C3"
    class="systemBar"
    style="position: fixed; width: 100%; z-index: 1030"
    height="40px"
  >
    <div class="container d-flex">
      <v-img
        :src="require('../assets/iconPhone.png')"
        max-height="16"
        max-width="16"
        class="imgCo"
      />
      <span class="ayuda">Ayuda y Soporte <a style="text-decoration: none; color: #ffffff;" href="https://wa.me/593999559378?text=Hola%20Clipp%20mi%20Nombre%20es" target="_back">+593999559378</a></span>

      <v-spacer></v-spacer>
<!--      <a href="https://a.clipp.eco/ClippFb" target="_blank" title="Facebook"-->
      <a href="https://www.facebook.com/ClippMovilidad" target="_blank" title="Facebook"
        ><v-icon color="#ffffff">{{ iconF }}</v-icon></a
      >&nbsp;
<!--      <a href="https://a.clipp.eco/ClippTw" target="_blank" title="twitter"-->
      <a href="https://www.twitter.com/clippmovilidad" target="_blank" title="twitter"
        ><v-icon color="#ffffff">{{ iconT }}</v-icon></a
      >&nbsp;
<!--      <a href="https://a.clipp.eco/ClippIg" target="_blank" title="Instagram"-->
      <a href="https://www.instagram.com/clippmovilidad" target="_blank" title="Instagram"
        ><v-icon color="#ffffff">{{ iconI }}</v-icon></a
      >
      &nbsp;
      <a
        class="espacioBanner"
        href="https://a.clipp.eco/ClippIn"
        target="_blank"
        title="Linkedin"
        ><v-icon color="#ffffff">{{ iconLi }}</v-icon></a
      >
    </div>
  </v-system-bar>
</template>

<script>
import { mdiFacebook } from "@mdi/js";
import { mdiTwitter } from "@mdi/js";
import { mdiInstagram } from "@mdi/js";
import { mdiLinkedin } from "@mdi/js";
export default {
  name: "App",
  components: {},

  data: () => ({
    iconF: mdiFacebook,
    iconT: mdiTwitter,
    iconI: mdiInstagram,
    iconLi: mdiLinkedin,
  }),
};
</script>

<style>

@font-face {
    font-family: Visby;
    src: url("../fonts/VisbyRoundCF-DemiBold.otf") format("opentype");
}

.imgCo {
  margin-left: 20px;
}

.espacioBanner {
  margin-right: 80px;
}

.ayuda {
  font-family: Visby !important;
  margin-left: 10px;
  color: #ffffff;
}
@media only screen and (max-width: 600px) {
  /* #navbarSupportedContent {
  justify-content: flex-end;
  flex: auto;
} */

  .imgCo {
    margin-left: 0px;
  }

  .espacioBanner {
    margin-right: 0px;
  }

  .ayuda {
    font-family: Visby;
    margin-left: 10px;
    color: #ffffff;
    font-size: smaller;
  }
}

@media only screen and (max-width: 750px) {
  /* #navbarSupportedContent {
  justify-content: flex-end;
  flex: auto;
} */

  .imgCo {
    margin-left: 0px;
  }

  .espacioBanner {
    margin-right: 20px;
  }

  .ayuda {
    font-family: Visby;
    margin-left: 10px;
    color: #ffffff;
  }
}

@media only screen and (max-width: 600px) {
  .imgCo {
    margin-left: 0px;
  }

  .espacioBanner {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 750px) {
  .imgCo {
    margin-left: 20px;
  }
  .espacioBanner {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .imgCo {
    margin-left: 80px;
  }
  .espacioBanner {
    margin-right: 80px;
  }
}
</style>
