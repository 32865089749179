import Vue from 'vue'
import VueRouter from 'vue-router'
import Portada from '../views/Portada.vue'
import Contactos from '../views/Contactos.vue'
//import QuienesSomos from '../components/QuienesSomos.vue'
import NotFound from '../components/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Portada',
    component: Portada
  },
  {
    path: '/contactos',
    name: 'Contactos',
    component: Contactos
  },
  {
    path: '/quienesSomos',
    name: 'quienesSomos',
    component: Portada
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: Portada
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: Portada
  },
  {
    path: '/clipper',
    name: 'clipper',
    component: Portada
  },
  {
    path: '/*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
