<template>
  <section>
    <div :style="inlineStyle">
      <div
        style="padding-top: 150px; text-align-last: center; text-align: center"
      >
        <span style="font-family: Visby; font-size: xx-large; color: aliceblue"
          >CONTACTO</span
        >
        <br />
        <a @click="$router.go(-1)"
          ><span
            style="
              font-family: visbyRegular;
              color: aliceblue;
              font-size: x-large;
            "
            >Inicio
          </span></a
        >
        <span
          style="
            font-family: visbyRegular;
            color: aliceblue;
            font-size: x-large;
          "
          >></span
        >
        <span
          style="font-family: visbyRegular; color: #37d1b3; font-size: x-large"
        >
          Contacto</span
        >
        <div style="padding-bottom: 120px"></div>
      </div>
    </div>

    <div class="container-fluid">
      <v-row>
        <v-col>
          <div class="contactanos">
            <span
              style="
                color: #37d1b3;
                font-family: visbyRegular;
                font-size: x-large;
                letter-spacing: 6px;
              "
              >CONTÁCTANOS</span
            >
            <br />
            <span
              style="
                color: #0043c3;
                font-family: visbyRegular;
                font-size: xxx-large;
              "
              >Hola, ¡<span
                style="color: #0043c3; font-family: visby; font-size: xxx-large"
                >Estamos para servirte</span
              >!</span
            >
            <br />

            <div style="padding-top: 20px">
              <!-- <span style="font-family: poppinsLigth; padding-top: 30px"
                >No dudes en ponerte en contacto con nosotros si tienes
                preguntas o dudas sobre nuestros servicios.
              </span>
              <br />
              <br /> -->
              <span style="font-family: poppinsSemiBold"
                >¡Transformamos la movilidad del país y el mundo!</span
              >
            </div>

            <div style="padding-left: 50px; padding-top: 20px">
              <div style="padding-bottom: 10px">
                <!-- <span style="font-family: poppinsSemiBold; color: #0043c3"
                  >Oficinas</span
                > -->
              </div>
              <!-- <div class="d-flex" style="align-items: center">
                <v-img
                  :src="require('../assets/iconCalle.png')"
                  max-height="16"
                  max-width="16"
                />
                &nbsp; &nbsp;<span style="font-family: poppinsLigth"
                  >Calles Anturios y Crisantemos</span
                >
              </div> -->
              <div class="d-flex" style="align-items: center">
                <v-img
                  :src="require('../assets/iconMensaje.png')"
                  max-height="16"
                  max-width="16"
                />
                &nbsp; &nbsp;
                <a
                  style="
                    text-decoration: none;
                    color: black;
                    font-family: poppinsLigth;
                  "
                  href="mailto:info@clipp.app"
                  target="_back"
                  ><span style="font-family: poppinsLigth"
                    >info@clipp.app</span
                  ></a
                >
              </div>

              <div class="d-flex" style="align-items: center; margin-top: 10px">
                <v-img
                  :src="require('../assets/iconTelefono.png')"
                  max-height="16"
                  max-width="16"
                />
                &nbsp; &nbsp;
                <a
                  style="text-decoration: none"
                  href="https://wa.me/593999559378?text=Hola%20Clipp%20mi%20Nombre%20es"
                  target="_back"
                  ><span style="font-family: poppinsSemiBold"
                    >Contacto Clipp</span
                  ></a
                >
              </div>
            </div>

            <!--             <div style="padding-left: 50px; padding-top: 20px">
              <div style="padding-bottom: 10px">
                <span style="font-family: poppinsSemiBold; color: #0043c3"
                  >Oficinas Quito</span
                >
              </div>
              <div class="d-flex" style="align-items: center">
                <v-img
                  :src="require('../assets/iconCalle.png')"
                  max-height="16"
                  max-width="16"
                />
                &nbsp; &nbsp;<span style="font-family: poppinsLigth"
                  >Av. de los Shirys y 10 de Agosto.</span
                >
              </div>
              <div class="d-flex" style="align-items: center">
                <v-img
                  :src="require('../assets/iconMensaje.png')"
                  max-height="16"
                  max-width="16"
                />
                &nbsp; &nbsp;
                <span style="font-family: poppinsLigth">hola@clipp.eco</span>
              </div>
              <div class="d-flex" style="align-items: center">
                <v-img
                  :src="require('../assets/iconTelefono.png')"
                  max-height="16"
                  max-width="16"
                />
                &nbsp; &nbsp;
                <span style="font-family: poppinsSemiBold">072 584 372</span>
              </div>
            </div> -->
          </div>
        </v-col>
        <v-col>
          <div class="contacto">
            <div class="escribenosTop">
              <v-card class="escribenos" elevation="0">
                <v-card-text>
                  <span
                    style="
                      color: #0043c3;
                      font-family: visby;
                      font-size: xx-large;
                    "
                  >
                    Escríbenos
                  </span>
                  <br />
                  <br />
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <div style="padding-bottom: 10px">
                      <span
                        style="
                          font-family: poppinsLigth;
                          color: #0043c3;
                          padding-left: 25px;
                        "
                        >Nombre y apellido *</span
                      >
                    </div>
                    <v-text-field
                      solo
                      elevation="0"
                      style="border-radius: 15px"
                      label="Ingresa tu nombre y apellido"
                      :rules="nameRules"
                      outlined
                      color="#37D1B3"
                      v-model="datoForm.nombres"
                    ></v-text-field>
                    <div style="padding-bottom: 10px">
                      <span
                        style="
                          font-family: poppinsLigth;
                          color: #0043c3;
                          padding-left: 25px;
                        "
                        >Correo</span
                      >
                    </div>
                    <v-text-field
                      solo
                      filled
                      style="border-radius: 15px"
                      label="Ingresa tu correo"
                      outlined
                      color="#37D1B3"
                      type="email"
                      :rules="emailRules"
                      v-model="datoForm.correo"
                    ></v-text-field>
                    <div style="padding-bottom: 10px">
                      <span
                        style="
                          font-family: poppinsLigth;
                          color: #0043c3;
                          padding-left: 25px;
                        "
                        >Teléfono *</span
                      >
                    </div>
                    <v-text-field
                      solo
                      filled
                      style="border-radius: 15px"
                      label="Ingresa tu teléfono"
                      :rules="telefono"
                      type="number"
                      outlined
                      color="#37D1B3"
                      v-model="datoForm.celular"
                    ></v-text-field>
                    <div style="padding-bottom: 10px">
                      <span
                        style="
                          font-family: poppinsLigth;
                          color: #0043c3;
                          padding-left: 25px;
                        "
                        >Mensaje *</span
                      >
                    </div>
                    <v-textarea
                      solo
                      dense
                      filled
                      style="border-radius: 15px"
                      label="Ingresa tu mensaje"
                      :rules="mensajeRules"
                      outlined
                      color="#37D1B3"
                      v-model="datoForm.mensaje"
                    ></v-textarea>
                    <v-row>
                      <v-col>
                        <v-btn
                          rounded
                          color="#37D1B3"
                          dark
                          @click="validate"
                          class="mr-2 text-transform-none"
                          :loading="enviarC"
                          ><span
                            style="
                              font-family: Visby;
                              text-transform: capitalize;
                            "
                            >Enviar</span
                          >
                          <v-icon right> {{ iconEnviar }} </v-icon></v-btn
                        >
                      </v-col>
                      <!-- <v-col>
                        <v-btn rounded color="error" dark @click="reset">
                          <span style="font-family: Visby">cancelar</span>
                          <v-icon right> {{ iconCancelar }}</v-icon>
                        </v-btn>
                      </v-col> -->
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <div>
      <!--  <MglMap style="height: 500px;" :accessToken="accessToken" :mapStyle.sync="mapStyle" :center="coordinates" zoom="14">
        <MglMarker :coordinates="coordinates" color="red" />
      </MglMap> -->

      <div style="height: 500px" id="map"></div>
    </div>
  </section>
</template>

<script>
import Mapbox from "mapbox-gl";
import { mdiCloseCircleOutline } from "@mdi/js";
import { mdiArrowRightCircleOutline } from "@mdi/js";
// var qs = require("qs");
// var md5 = require("md5");
// var sha256 = require("js-sha256");

export default {
  components: {
    //Mapbox
    //MglMap,
    /* MglNavigationControl,
    MglGeolocateControl, */
    //MglMarker
  },
  setup() {},

  data() {
    return {
      image: "@/assets/fondoContacto.png",
      accessToken:
        "pk.eyJ1IjoiZGllZ28xMDExIiwiYSI6ImNqeHZhMHoydjAycTgzY3M1MXNudWRqcWEifQ.BClY9jJH-4km-OHlROK2lA", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/streets-v9", // your
      mapbox: "",
      coordinates: [-79.2036816, -4.018121],
      mapa: "",
      valid: true,
      nameRules: [
        (v) => !!v || "Nombre requerido",
        (v) =>
          (v && v.length >= 5) || "El nombre debe tener mínimo 5 caracteres",
      ],
      emailRules: [
        (v) => {
          if (
            v &&
            v.length > 0 &&
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) == false
          )
            return "El correo debe ser válido";
          return true;
        },
      ],
      telefono: [
        (v) => !!v || "Teléfono requerido",
        (v) => {
          if (v && v.length < 7)
            return "El teléfono debe tener mínimo 7 caracteres";
          else if (v && v.length > 10)
            return "El teléfono debe tener máximo 10 caracteres";
          else return true;
        },
      ],
      mensajeRules: [
        (v) => !!v || "Mensaje requerido",
        (v) =>
          (v && v.length >= 20) || "El mensaje debe tener mínimo 20 caracteres",
      ],
      iconEnviar: mdiArrowRightCircleOutline,
      iconCancelar: mdiCloseCircleOutline,
      datoForm: {
        nombres: "",
        correo: "",
        celular: "",
        mensaje: "",
      },
      datoFormDefault: {
        nombres: "",
        correo: "",
        celular: "",
        mensaje: "",
      },
      enviarC: false,
    };
  },

  created() {
    console.log("cerrando de contacto");
    this.mapbox = Mapbox;
    if (document.getElementsByClassName("navbar-toggler")[0]) {
      let element = document.getElementsByClassName("navbar-toggler")[0];
      if (element.getAttribute("aria-expanded") == "true") {
        element.click();
      }
    }
    document.body.scrollIntoView();
  },

  mounted() {
    this.cargarMapa();
    document.body.scrollIntoView();
  },

  computed: {
    bgImage() {
      return require("@/assets/fondoContacto.png");
    },
    inlineStyle() {
      return {
        backgroundImage: `url(${this.bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      };
    },
  },

  methods: {
    cargarMapa() {
      Mapbox.accessToken = this.accessToken;
      this.mapa = new Mapbox.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-79.2036816, -4.018121],
        zoom: 14,
      });
      const nav = new Mapbox.NavigationControl();
      this.mapa.addControl(nav, "top-right");

      // eslint-disable-next-line no-unused-vars
      const popup = new Mapbox.Popup({ offset: 25 }).setHTML(
        "<span>Clipp, tu aplicación que te conecta con la movilidad de tu ciudad.</span>"
      );

      const el = document.createElement("div");
      const el1 = document.createElement("div");
      var dateSpan = document.createElement("span");
      dateSpan.innerHTML = "Clipp | Mejoramos tu mundo";
      el1.id = "markerPadre";
      el.id = "marker";
      dateSpan.id = "spanMapa";
      el1.appendChild(dateSpan);
      el1.appendChild(el);

      // eslint-disable-next-line no-unused-vars
      const marker2 = new Mapbox.Marker(el1)
        .setLngLat([-79.2036816, -4.018121])
        .setPopup(popup)
        .addTo(this.mapa);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.enviarContacto();
        /* this.openNotification(
          "",
          "success",
          "Envio mensaje",
          "Contanto enviado correctamente"
        );
        this.reset(); */
      } else {
        this.openNotification(
          "",
          "danger",
          "Envio mensaje",
          "Por favor llene todos los campos"
        );
      }
    },
    reset() {
      this.$refs.form.reset();
      this.datoForm = this.datoFormDefault;
      console.log(this.datoForm);
      this.enviarC = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    openNotification(position = null, color, titulo, mensaje) {
      // eslint-disable-next-line no-unused-vars
      const noti = this.$vs.notification({
        progress: "auto",
        duration: 3000,
        color,
        position,
        title: titulo,
        text: mensaje,
      });
    },
    enviarContacto() {
      console.log(this.datoForm);
      this.enviarC = true;
      let global = this;
      var data = {
        cityId: parseInt(process.env.VUE_APP_URL_ID_CIUDAD),
        applicationId: parseInt(process.env.VUE_APP_URL_ID_APLICATIVO),
        application: "Contactos web - Clipp Corporativo",
        names: this.datoForm.nombres,
        surnames: " ",
        countryCode: " ",
        phone: this.datoForm.celular,
        mail:
          this.datoForm.correo && this.datoForm.correo != ""
            ? this.datoForm.correo
            : " ",
        identification: "S/N",
        city: "S/N",
        country: "S/N",
        message: this.datoForm.mensaje,
        reason: "Contáctenos web Clipp",

      };
      var config = {
        method: "post",
        url:
          `${process.env.VUE_APP_BASE_URL_GALACTUS}/v1/contact/create`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer  ${process.env.VUE_APP_TOKEN}`
        },
        data: data,
      };

      this.axios(config)
        .then(function (response) {
          console.log(response);
          if (response.data && response.data.statusCode === 0) {
            global.openNotification(
              "",
              "success",
              "Envio de mensaje",
              "Contacto enviado correctamente"
            );
            global.reset();
          } else {
            global.openNotification(
              "",
              "danger",
              "Envio de mensaje",
              "No se logro enviar el mensaje. Por favor inténtelo más tarde..."
            );
            global.reset();
          }
        })
        .catch(function (error) {
          console.log(error);
          global.openNotification(
            "",
            "danger",
            "Envio de mensaje",
            "No se logro enviar el mensaje. Por favor inténtelo más tarde..."
          );
          global.reset();
        });
    },
  },
};
</script>

<style>
font-face {
  font-family: poppinsLigth;
  src: url("../fonts/Poppins-Light.ttf") format("opentype");
}

@font-face {
  font-family: poppinsSemiBold;
  src: url("../fonts/Poppins-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: visbyRegular;
  src: url("../fonts/VisbyRoundCF-Regular.otf") format("opentype");
}

@font-face {
  font-family: Visby;
  src: url("../fonts/VisbyRoundCF-DemiBold.otf") format("opentype");
}

@font-face {
  font-family: VisbyBold;
  src: url("../fonts/VisbyRoundCF-Bold.otf") format("opentype");
}

.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 9px);
  font-family: poppinsLigth;
}

.labelC .v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border-radius: 16px;
  border-color: red;
}

.contacto {
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

/* #map{
  width: 100%;
  height: 500px;
} */

#map {
  position: relative;
  bottom: 0;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0;
  left: 0;
}

.contactanos {
  margin-left: 80px;
  margin-top: 80px;
  margin-right: 80px;
  margin-bottom: 80px;
}

.escribenos {
  background-color: #f9f9f9 !important;
  padding: 25px;
}

.escribenosTop {
  padding-top: 20px;
}

.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgb(0 0 0 / 0%) !important;
}
@media only screen and (max-width: 600px) {
  .contacto {
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
  }

  .contactanos {
    margin-left: 40px;
    margin-top: 40px;
    margin-right: 40px;
    margin-bottom: 20px;
  }

  .escribenos {
    background-color: #f9f9f9;
    padding: 10px;
  }

  .escribenosTop {
    padding-top: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .contacto {
    margin-top: 5px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
  }

  .escribenos {
    background-color: #f9f9f9;
    padding: 10px;
  }

  .escribenosTop {
    padding-top: 10px;
  }

  #marker {
    background-image: url("https://clipp.eco/wp-content/uploads/2022/07/clipp-logo-mail.png");
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }

  #markerPadre {
    text-align: -webkit-center;
  }

  #spanMapa {
    font-size: large;
    color: #1c1ccc;
    font-weight: 500;
  }
}
</style>
