<template>
  <div class="espacio scrollspy-example" data-bs-spy="scroll" data-bs-target="#navbar-example2"
    data-bs-root-margin="0px 0px -40%" tabindex="0">
    <section id="inicio" class="inicioI">
      <!-- <div
        v-if="sheet"
        style="padding-top: 15%; padding-left: 15%; padding-right: 20%"
       
      >
        <v-sheet class="pt-20" :style="colorMain">
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>
        </v-sheet>
      </div> -->
      <div class="inicioI">
        <v-img class="inicioI" :src="require('../assets/portada13.png')" />
        <div :style="{
          width: 'auto',
          height: 'auto',
          'background-size': 'cover',
        }">
          <div class="bannerP">
            <v-card aling="center" rounded class="dialogoMensaje">
              <v-card-title style="padding-left: 3%; padding-right: 3%">
                <v-row class="rowPortada">
                  <v-col class="d-flex colPortada" cols="12" xs="12" sm="12" md="12" lg="8"
                    style="justify-content: center; align-items: center">
                    <span class="overflow-visible titulo">
                      <span style="font-family: poppinsSemiBold">¡Descárgala ahora</span>
                      y disfruta
                      <span style="font-family: poppinsSemiBold">un mundo</span>
                      de opciones!
                    </span>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="4" style="
                      justify-content: center;
                      align-items: center;
                      text-align: center;
                    ">
                    <a href="https://bit.ly/ClippAnd" target="_blank"><img :src="require('../assets/tiendas/play.png')"
                        class="imgD" alt="Descargala en Play Store" title="Descargala en Play Store" /></a>

                    <a href="https://bit.ly/ClippMov" target="_blank"><img :src="require('../assets/tiendas/apple.png')"
                        class="imgD" alt="Descargala en App Store" title="Descargala en App Store" /></a>

                    <a href="https://bit.ly/ClippAppGallery" target="_blank"><img
                        :src="require('../assets/tiendas/galery.png')" class="imgD" alt="Descargala en AppGallery "
                        title="Descargala en AppGallery " /></a>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </div>
        </div>
      </div>
      <div style="background-color: white" class="divSpacio"></div>
    </section>

    <section id="quienesSomos">
      <div class="quienesSomosDiv">
        <v-row>
          <v-col cols="12" xs="6" sm="12" md="12" lg="6" class="colQ">
            <div class="colQ">
              <span style="
                  color: #37d1b3;
                  font-family: visbyRegular;
                  font-size: large;
                  font-weight: 300;
                  letter-spacing: 6px;
                ">QUIENES SOMOS</span>
              <br />
              <span class="quieresF">¿Qué es
                <span style="font-family: VisbyBold">clipp?</span></span>
              <br />

              <span style="
                  color: black;
                  font-family: poppinsLigth;
                  font-weight: 300;
                  text-align: justify;
                  display: block;
                  margin-top: 25px;
                ">Es una aplicación que te permite buscar, agendar y utilizar
                diferentes alternativas de transporte conectándote con la
                movilidad de tu ciudad en un concepto de Movilidad como servicio
                - MaaS (Mobility as a Service).</span>
              <span style="
                  color: black;
                  font-family: poppinsLigth;
                  font-weight: 300;
                  text-align: justify;
                  display: block;
                  margin-top: 25px;
                ">
                Te ayudamos a movilizarte en taxi, transporte urbano (bus, tren
                y tranvía), scooter y bicicleta.
              </span>
              <div style="padding-top: 20px">
                <!-- <v-btn rounded color="#37D1B3" dark>
                  <span style="font-family: Visby">Conoce Más</span>
                  <v-icon right> {{ iconFlecha }} </v-icon>
                </v-btn> -->
              </div>
            </div>
          </v-col>
          <v-col cols="12" xs="6" sm="12" md="12" lg="6">
            <div style="
                text-align: center;
                overflow: hidden;
                position: relative;
                width: 100%;
              ">
              <!--  <v-img
                @click="abrirVideo"
                :src="require('../assets/computadora.png')"
                contain
              >
              <v-icon
                @click="abrirVideo"
                :style="cssVideo"
                :size="window.width < 600 ? '90' : '120'"
                color="#37d1b3"
                >{{ iconPlay1 }}</v-icon
              >
             </v-img> -->
              <iframe class="iframeVideo" src="https://www.youtube.com/embed/-4ET1xYB64o?si=xadBVIM-dmKt1RYJ" title="YouTube video player"
                encrypted-media allowfullscreen frameborder="0" allow="autoplay" id="music"></iframe>
            </div>

            <!--  </a> -->
          </v-col>
        </v-row>
      </div>
      <div style="margin-top: 50px"></div>
    </section>

    <section id="servicios">
      <div :style="{
        'background-image':
          'url(' + require('../assets/fondoServicios1.svg') + ')',
        'background-size': 'cover',
      }">
        <div class="serviciosP">
          <div style="text-align: center">
            <span style="
                color: #fff;
                font-family: visbyRegular;
                font-size: large;
                font-weight: 400;
                letter-spacing: 6px;
              ">
              <br />
              · SERVICIOS ·
            </span>
            <div style="margin-top: 20px"></div>
            <span style="
                color: #fff;
                font-family: VisbyRegular;
                font-size: xxx-large;
                font-weight: 500;
              ">
              ¡Libertad de
              <span style="font-family: VisbyBold">moverte</span> como
              <span style="font-family: VisbyBold">desees!</span>
            </span>
            <br />
            <br />
            <br />
            <div class="d-flex serviciosM">
              <v-card class="maas" elevation="0">
                <span class="textS">MaaS (Movilidad Como Servicio)</span>
                <div class="d-flex justify-content-center flex-wrap cardS">
                  <v-card class="cardSSS cardEspacio" height="125" width="125" @click="hrefLink('https://ktaxi.com.ec')"
                    @mouseover="hover = true" @mouseleave="hover = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverTaxi" alt="Servicio de Taxis" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Taxi</span>
                    </v-card-text>
                  </v-card>
                  <v-card class="cardSSS cardEspacio" height="125" width="125"
                    @click="hrefLink('https://kbus.kradac.com')" @mouseover="hoverBus = true"
                    @mouseleave="hoverBus = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverBuses" alt="Servicio de Bus" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Bus</span>
                    </v-card-text>
                  </v-card>
                  <v-card class="cardSSS cardEspacio" height="125" width="125"
                    @click="hrefLink('https://app.clipp.eco/clipp')" @mouseover="hoverScooter = true"
                    @mouseleave="hoverScooter = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverScooters" alt="Servicio de Scooter" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Scooter</span>
                    </v-card-text>
                  </v-card>
                  <v-card class="cardSSS cardEspacio" height="125" width="125"
                    @click="hrefLink('https://app.clipp.eco/clipp')" @mouseover="hoverBici = true"
                    @mouseleave="hoverBici = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverBicicleta" alt="Servicio de Bicicleta" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Bicicleta</span>
                    </v-card-text>
                  </v-card>
                  <v-card class="cardSSS cardEspacio" height="125" width="125"
                    @click="hrefLink('https://app.clipp.eco/clipp')" @mouseover="hoverCar = true"
                    @mouseleave="hoverCar = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverCarro" alt="Servicio de Carshing" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Carsharing</span>
                    </v-card-text>
                  </v-card>
                </div>
              </v-card>

              <v-card class="laas" elevation="0">
                <span class="textS">LaaS (Vida Como Servicio)</span>

                <div class="d-flex justify-content-center flex-wrap cardS">
                  <v-card class="cardSSS cardEspacio" height="125" width="125"
                    @click="hrefLink('https://app.clipp.eco/clipp')" @mouseover="hoverBilletera = true"
                    @mouseleave="hoverBilletera = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverBilleteras" alt="Servicio de Pagos electrónicos" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Billetera</span>
                    </v-card-text>
                  </v-card>
                  <v-card class="cardSSS cardEspacio" height="125" width="125"
                    @click="hrefLink('https://app.clipp.eco/clipp')" @mouseover="hoverEnco = true"
                    @mouseleave="hoverEnco = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverEncomienda" alt="Servicio de Encomiendas" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Encomienda</span>
                    </v-card-text>
                  </v-card>
                  <v-card class="cardSSS cardEspacio" height="125" width="125"
                    @click="hrefLink('https://app.clipp.eco/clipp')" @mouseover="hoverEvento = true"
                    @mouseleave="hoverEvento = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverEventos" alt="Servicio de Eventos" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Eventos</span>
                    </v-card-text>
                  </v-card>
                  <v-card class="cardSSS cardEspacio" height="125" width="125"
                    @click="hrefLink('https://web.clipp.eco/delivery/#/')" @mouseover="hoverComida = true"
                    @mouseleave="hoverComida = false">
                    <v-card-text class="font-weight-medium mt-1 text-center text-subtitle-1">
                      <img :src="hoverFood" alt="Servicio de Delivery" /><br />

                      <span style="font-size: medium; font-family: poppinsSemiBold">Delivery</span>
                    </v-card-text>
                  </v-card>
                </div>
                <br />
                <br />
              </v-card>
            </div>
          </div>
        </div>
        <br />
        <div class="divSpacio"></div>
      </div>

      <!--  <v-img  :src="require('../assets/fondoVerde.png')" /> -->
    </section>

    <section id="clipper" style="background-color: #f9f9f9">
      <div class="clipperC">
        <v-row>
          <v-col cols="12" xs="6" sm="12" md="12" lg="6" style="margin-top: 50px">
            <v-img :src="require('../assets/clipper22.png')" />
          </v-col>
          <v-col cols="12" xs="6" sm="12" md="12" lg="6" class="container-fluid colClipper">
            <div class="colClipperT">
              <span class="quiero">QUIERO SER UN CLIPPER</span>
              <br />
              <span class="quieresF">¿Quieres formar
                <span style="font-family: VisbyBold">parte</span> de nuestro
                <span style="font-family: VisbyBold">team</span>?</span>
              <span class="enClipp">En Clipp, contamos con el mejor equipo logístico. Si quieres
                ser parte de nuestros Clippers, escríbenos.
                <span style="font-family: poppinsSemiBold">¡Queremos conocerte!</span>
              </span>
              <br />

              <div style="padding-top: 15px">
                <v-row>
                  <v-col style="padding-bottom: 2px">
                    <v-btn rounded color="#37D1B3" dark width="270px" @click="abrirRegistroClipp">
                      <span style="font-family: Visby">Formar una alianza</span>
                      <v-icon right> {{ iconCafe }} </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn rounded color="#37D1B3" dark width="270px" @click="abrirRegistroClipp">
                      <span style="font-family: Visby">Registrarme como Clipper</span>
                      <v-icon right> {{ iconBien }} </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <br />
    </section>

    <section>
      <div class="container-fluid">
        <br />
        <br />
        <br />
        <carousel-3d :autoplay="true" :autoplay-timeout="2000" :height="270" :perspective="0" :space="400">
          <slide v-for="(slide, i) in imgNosotros" :index="i" :key="i">
            <img :src="require('../assets/nosotros/' + `${slide.img}`)" :alt="`${slide.alt}`" />
          </slide>
        </carousel-3d>
      </div>
      <br />
      <br />
      <br />
      <br />
    </section>

    <!--  <section>
      <center>
        <span
          style="
            color: #18e9c1;
            font-family: visbyRegular;
            font-size: large;
            font-weight: 400;
            letter-spacing: 6px;
          "
          >NUESTROS LOGROS</span
        >
      </center>
      <br />
      <br />
      <br />
      <timeline :items="premios" />
    </section> -->

    <section style="background-color: #f9f9f9">
      <center style="padding-top: 50px">
        <span style="
            color: #18e9c1;
            font-family: visbyRegular;
            font-size: large;
            font-weight: 400;
            letter-spacing: 6px;
          ">NUESTROS LOGROS</span>
      </center>
      <br />
      <br />
      <br />
      <vs-card-group class="cardGroup">
        <vs-card v-for="items in premios" :key="items.content" width="100%" type="4" class="cardGroup">
          <template #title>
            <h3 style="color: #0043c3; font-family: poppinsLigth">
              {{ items.title }}
            </h3>
          </template>
          <template #img>
            <img :src="require('../assets/premios/' + `${items.imgS}`)" :alt="`${items.alt}`" />
          </template>
          <template #text style="width: 100%">
            <p style="font-family: poppinsLigth">{{ items.content }}</p>
          </template>
          <!-- <template #interactions>
            <vs-button danger icon>
              <i class="bx bx-heart"></i>
            </vs-button>
            <vs-button class="btn-chat" shadow primary>
              <i class="bx bx-chat"></i>
              <span class="span"> 54 </span>
            </vs-button>
          </template> -->
        </vs-card>
      </vs-card-group>
    </section>

    <section>
      <div class="container-fluid apoyo">
        <center>
          <span style="
              color: #18e9c1;
              font-family: visbyRegular;
              font-size: large;
              font-weight: 400;
              text-align: center;
              margin-bottom: 50px;
              letter-spacing: 6px;
            ">
            CON EL APOYO DE
          </span>
        </center>
        <v-sheet max-width="100%" class="sheetApoyo">
          <v-slide-group multiple show-arrows>
            <v-slide-item v-for="n in apoyo" :key="n.id" v-slot="{ active, toggle }">
              <v-card :color="active ? '' : ''" @click="toggle" class="ma-4 cardApoyo" max-width="250">
                <v-img :src="require('../assets/' + `${n.img}`)" />
                <v-row class="fill-height" align="center" justify="center">
                  <v-scale-transition>
                    <!--  <v-icon
                v-if="active"
                color="white"
                size="48"
                v-text="'mdi-close-circle-outline'"
              ></v-icon> -->
                  </v-scale-transition>
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>
    </section>

    <div v-if="dialog">
      <v-row justify="center">
        <v-dialog v-model="dialog" width="600px" height="400px" overlay-opacity="0.9" @click:outside="closeDialog">
          <iframe class="iframeVideo" src="https://www.youtube.com/embed/c6_wGaZrEss" title="YouTube video player"
            encrypted-media allowfullscreen frameborder="0" allow="autoplay" id="music"></iframe>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
//import $ from "jquery";
import $ from "jquery";
import { Carousel3d, Slide } from "vue-carousel-3d";
//import timeline from "./line.vue";
import { mdiArrowRightThinCircleOutline } from "@mdi/js";
import { mdiCoffee } from "@mdi/js";
import { mdiCheckboxMarkedCircleOutline } from "@mdi/js";
import imageTaxi from "../assets/iconosServicios/azul/icono-7.png";
import imagenTaxiH from "../assets/iconosServicios/blanco/icono-6.png";
import imageBus from "../assets/iconosServicios/azul/icono-11.png";
import imagenBusH from "../assets/iconosServicios/blanco/icono-10.png";
import imageScooter from "../assets/iconosServicios/azul/icono-9.png";
import imagenScooterH from "../assets/iconosServicios/blanco/icono-8.png";
import imageBici from "../assets/iconosServicios/azul/icono-5.png";
import imagenBiciH from "../assets/iconosServicios/blanco/icono-4.png";
import imageCar from "../assets/iconosServicios/azul/icono-13.png";
import imagenCarH from "../assets/iconosServicios/blanco/icono-12.png";
import imageComida from "../assets/iconosServicios/azul/icono-17.png";
import imagenCamidaH from "../assets/iconosServicios/blanco/icono-16.png";
import imageBilletera from "../assets/iconosServicios/azul/icono-1.png";
import imagenBilleteraH from "../assets/iconosServicios/blanco/icono.png";
import imageEnco from "../assets/iconosServicios/azul/icono-3.png";
import imagenEncoH from "../assets/iconosServicios/blanco/icono-2.png";
import imageEvento from "../assets/iconosServicios/azul/icono-15.png";
import imagenEventoH from "../assets/iconosServicios/blanco/icono-14.png";
import { mdiMotionPlay } from "@mdi/js";
import { mdiPlayCircleOutline } from "@mdi/js";

export default {
  name: "HelloWorld",
  components: {
    Carousel3d,
    Slide,
    //timeline,
  },

  data: () => ({
    items: [
      {
        color: "white",
        imagen: "taxi1.png",
        nombre: "Taxi",
        colorLetra: "white",
        link: "https://ktaxi.com.ec",
      },
      {
        color: "white",
        imagen: "bus.png",
        nombre: "Bus",
        link: "https://kbus.kradac.com",
      },
      {
        color: "white",
        imagen: "scooter.png",
        nombre: "Scooter",
        link: "",
      },
      {
        color: "white",
        imagen: "bici.png",
        nombre: "Bicicleta",
        link: "",
      },
      {
        color: "white",
        imagen: "Car.png",
        nombre: "Carshing",
        link: "",
      },
    ],
    itemsL: [
      {
        color: "white",
        imagen: "comida.png",
        nombre: "Comida",
        href: "https://web.clipp.eco/delivery/#/",
      },
      {
        color: "white",
        imagen: "billetera.png",
        nombre: "Billetera",
        href: "",
      },
      {
        color: "white",
        imagen: "encomienda.png",
        nombre: "Encomienda",
        href: "",
      },
      {
        color: "white",
        imagen: "eventos.png",
        nombre: "Eventos",
        href: "",
      },
    ],
    imgNosotros: [
      {
        img: "brunobici.png",
        alt: "Bicileta",
        id: 1,
      },
      {
        img: "carrusel1.png",
        alt: "Scooter",
        id: 2,
      },
      {
        img: "sctr2.png",
        alt: "Scooter",
        id: 3,
      },
      {
        img: "taxi.png",
        alt: "Taxi",
        id: 4,
      },
      {
        img: "carrusel2.png",
        alt: "Scooter",
        id: 5,
      },
    ],
    verVideo: false,
    dialog: false,
    premios: [
      {
        content: "2019 - Primer Lugar Reto Digital / Ecuador",
        title: "Primer Lugar",
        img: "https://clipp.eco/wp-content/uploads/2020/01/4.png",
        band: "2",
        alt: "Reto Digital Diners 2019.",
        imgS: "premio2.png",
      },
      {
        content: "2019 - Segundo Lugar Premios Innova Ciudad / Colombia",
        title: "Segundo Lugar",
        img: "https://clipp.eco/wp-content/uploads/2021/03/Smart-City-Business-Colombia.png",
        band: "2",
        alt: "Smart City Colombia 2019",
        imgS: "premio4.png",
      },
      {
        content: "2019 - Primer Lugar Premios Intertraffic Latam / México",
        title: "Primer Lugar",
        img: "https://clipp.eco/wp-content/uploads/2020/01/5.png",
        band: "1",
        alt: "Premios Intertraffic Award México 2019.",
        imgS: "premio1.png",
      },
      {
        content:
          "2019 - Segundo Lugar Concurso de emprendimiento sostenible de IIGE Ecuador",
        title: "Segundo Lugar",
        img: "https://clipp.eco/wp-content/uploads/2020/01/2.png",
        band: "1",
        alt: "Emprendimiento Sostenible de la IIGE",
        imgS: "premio3.png",
      },
      {
        content: "2020 - Primer Lugar StartUp por la Movilidad, México",
        title: "Primer Lugar",
        img: "https://clipp.eco/wp-content/uploads/2020/08/primerLugar.jpeg",
        band: "1",
        alt: "Startups por la Movilidad México 2020",
        imgS: "premio5.jpeg",
      },
      {
        content:
          "2020 - Primer lugar en América Latina de los premios innovación social de la fundación Mapfre de España.",
        title: "Primer Lugar América",
        img: "https://clipp.eco/wp-content/uploads/2020/08/mafre.jpeg",
        band: "2",
        alt: "Premios a la Innovación Social",
        imgS: "premio6.jpeg",
      },
      {
        content: "2021 - Tercer Lugar Mobility StartUp Challenge of Y4TP ",
        title: "Tercer Lugar",
        img: "https://clipp.eco/wp-content/uploads/2021/07/premio-UTIP.png",
        band: "1",
        alt: "Mobility StartUp Challenge of Y4TP",
        imgS: "premio7.png",
      },
      {
        content:
          "2022- Segundo Lugar, StartUp Olé- Competición de Pitch México",
        title: "Segundo Lugar",
        img: "https://clipp.eco/wp-content/uploads/2022/04/WhatsApp-Image-2022-04-05-at-4.07.54-PM.jpeg",
        band: "2",
        alt: "Startup OLÉ México",
        imgS: "oled.png",
      },
    ],
    swiper: "",
    apoyo: [
      {
        id: 1,
        img: "endeavor.png",
      },
      {
        id: 2,
        img: "utpl.png",
      },
      {
        id: 3,
        img: "impa.png",
      },
      {
        id: 4,
        img: "platzi.png",
      },
      {
        id: 5,
        img: "prendho.png",
      },
      {
        id: 6,
        img: "emprendec.png",
      },
      {
        id: 7,
        img: "quo.png",
      },
      {
        id: 8,
        img: "y4pt.png",
      },
      {
        id: 9,
        img: "AEI.png"
      }, 
      {
        id: 10,
        img: "CFL.png"
      },
      {
        id: 11,
        img: "CITEC.png"
      }
    ],
    model: null,
    iconFlecha: mdiArrowRightThinCircleOutline,
    iconCafe: mdiCoffee,
    iconBien: mdiCheckboxMarkedCircleOutline,
    isLoaded: true,
    imagenTaxi: imageTaxi,
    imagenTaxiH: imagenTaxiH,
    imagenBus: imageBus,
    imagenBusH: imagenBusH,
    imagenScooter: imageScooter,
    imagenScooterH: imagenScooterH,
    imagenBici: imageBici,
    imagenBiciH: imagenBiciH,
    imagenCar: imageCar,
    imagenCarH: imagenCarH,
    imagenComida: imageComida,
    imagenComidaH: imagenCamidaH,
    imagenBilletera: imageBilletera,
    imagenBilleteraH: imagenBilleteraH,
    imagenEnco: imageEnco,
    imagenEncoH: imagenEncoH,
    imagenEvento: imageEvento,
    imagenEventoH: imagenEventoH,
    hover: false,
    hoverBus: false,
    hoverScooter: false,
    hoverBici: false,
    hoverCar: false,
    hoverComida: false,
    hoverBilletera: false,
    hoverEnco: false,
    hoverEvento: false,
    iconPlay: mdiMotionPlay,
    iconPlay1: mdiPlayCircleOutline,
    window: {
      width: 0,
      height: 0,
    },
    /* sheet: true,
    colorMain: {
      backgroundColor: "#0d68f8",
    }, */
  }),

  created() {
    console.log("CREANDO PRINICPAL");
    console.log(this.$router);
    if (this.$router.history.current.fullPath != "/") {
      console.log("direfente");
      console.log(this.$router.history.current.fullPath);
      var name = this.$router.history.current.name;
      console.log(name);
      $(document).ready(function () {
        console.log("ready");
        setTimeout(function () {
          window.location.href = $("." + name).attr("href");
        }, 1000);
      });
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  methods: {
    abrirVideo() {
      console.log("Abriendo video");
      //this.verVideo = true;
      this.dialog = true;
    },
    cerrarVideo() {
      this.verVideo = false;
    },

    closeDialog() { },

    hrefLink(link) {
      if (link && link.length > 0) window.open(link, "_blank").focus();
    },

    loaded() {
      console.log("cargada");
      /* this.sheet = false;
      this.colorMain.backgroundColor = "#0bc5a0";
      console.log(this.colorMain); */
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    abrirRegistroClipp() {
      //window.open(process.env.VUE_APP_URL_CONTACTO, "_blank").focus();
      window.open("/#/contactos", "_blank").focus();
    },
  },

  mounted() { },

  watch: {},

  computed: {
    hoverTaxi() {
      if (this.hover == true) {
        return this.imagenTaxiH;
      } else {
        return this.imagenTaxi;
      }
    },
    hoverBuses() {
      if (this.hoverBus == true) {
        return this.imagenBusH;
      } else {
        return this.imagenBus;
      }
    },
    hoverScooters() {
      if (this.hoverScooter == true) {
        return this.imagenScooterH;
      } else {
        return this.imagenScooter;
      }
    },
    hoverBicicleta() {
      if (this.hoverBici == true) {
        return this.imagenBiciH;
      } else {
        return this.imagenBici;
      }
    },
    hoverCarro() {
      if (this.hoverCar == true) {
        return this.imagenCarH;
      } else {
        return this.imagenCar;
      }
    },
    hoverFood() {
      if (this.hoverComida == true) {
        return this.imagenComidaH;
      } else {
        return this.imagenComida;
      }
    },
    hoverBilleteras() {
      if (this.hoverBilletera == true) {
        return this.imagenBilleteraH;
      } else {
        return this.imagenBilletera;
      }
    },
    hoverEncomienda() {
      if (this.hoverEnco == true) {
        return this.imagenEncoH;
      } else {
        return this.imagenEnco;
      }
    },
    hoverEventos() {
      if (this.hoverEvento == true) {
        return this.imagenEventoH;
      } else {
        return this.imagenEvento;
      }
    },

    cssVideo() {
      return {
        "margin-top": this.window.width < 600 ? "40px" : "120px",
        "margin-left": "44%",
      };
    },
  },
};
</script>

<style src="../assets/css/main.css" scoped></style>
