import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";
import Carousel3d from 'vue-carousel-3d';
import VueHorizontalTimeline from "vue-horizontal-timeline";
import Vuesax from 'vuesax'
import axios from 'axios'
import VueAxios from 'vue-axios'




import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})

Vue.use(VueHorizontalTimeline);

Vue.use(Carousel3d);

Vue.use(VueMapbox, { mapboxgl: Mapbox });

Vue.component('LazyYoutubeVideo', LazyYoutubeVideo)

Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
