<template>
  <v-footer dark padless>
    <v-card
      flat
      tile
      class="white--text text-center"
      color="#0043C3"
      width="100%"
    >
      <v-card-text class="white--text pt-0">
        <div
          style="margin-left: 30px; margin-right: 30px; margin-top: 30px"
          class="d-flex flex-column flex-md-row flex-xl-row flex-sm-column"
        >
          <v-img :src="require('../assets/clippFooter.png')" max-width="200" />
          <div
            class="d-flex justify-content-end"
            style="width: 100%; align-items: center"
          >
            <a
              style="padding-left: 10px; padding-right: 10px"
              href="https://www.facebook.com/ClippMovilidad"
              target="_blank"
              title="Facebook"
              ><v-icon>{{ iconF }}</v-icon></a
            >&nbsp;
            <a
              style="padding-left: 10px; padding-right: 10px"
              href="https://www.twitter.com/clippmovilidad"
              target="_blank"
              title="Twitter"
              ><v-icon>{{ iconT }}</v-icon></a
            >&nbsp;
            <a
              style="padding-left: 10px; padding-right: 10px"
              href="https://www.instagram.com/clippmovilidad"
              target="_blank"
              title="Instagram"
              ><v-icon>{{ iconI }}</v-icon></a
            >&nbsp;
            <a
              style="padding-left: 10px; padding-right: 10px"
              href="https://a.clipp.eco/ClippIn"
              target="_blank"
              title="Linkedin"
              ><v-icon>{{ iconLi }}</v-icon></a
            >
          </div>
        </div>
        <div style="margin-left: 30px; margin-right: 30px; margin-top: 30px">
          <v-row>
            <v-col style="text-align: initial">
              <span
                style="
                  color: rgb(56 209 179);
                  font-family: visby;
                  font-size: large;
                  font-weight: 500;
                "
                >Enlaces de interés </span
              ><br />
              <span
                style="font-family: poppinsLigth"
                @click="hrefEnlaces(url + '/#/quienesSomos')"
                >Quiénes Somos</span
              ><br />
              <span
                style="font-family: poppinsLigth"
                @click="hrefEnlaces('https://clipp.eco/blog/')"
                >Blog</span
              ><br />
              <span style="font-family: poppinsLigth" @click="hrefEnlaces('')"
                >Formar una alianza</span
              ><br />
              <span style="font-family: poppinsLigth" @click="hrefEnlaces('')"
                >Registrarme como Clipper</span
              ><br />
              <span
                style="font-family: poppinsLigth"
                @click="hrefEnlaces('https://web.clipp.eco/ktaxicliente3.0/')"
                >Corporativo Taxis</span
              ><br />
              <span style="font-family: poppinsLigth" @click="hrefEnlaces('')"
                >Corporativo Buses</span
              ><br />
              <span
                style="font-family: poppinsLigth"
                @click="hrefEnlaces(url + '/#/contactos')"
                >Contactos</span
              ><br />
            </v-col>
            <v-col style="text-align: initial">
              <span
                style="
                  color: rgb(56 209 179);
                  font-family: visby;
                  font-size: large;
                  font-weight: 500;
                "
                >Servicios para ti </span
              ><br />
              <span
                style="font-family: poppinsLigth"
                @click="hrefEnlaces('https://ktaxi.com.ec')"
                >Taxis</span
              ><br />
              <span
                style="font-family: poppinsLigth"
                @click="hrefEnlaces('https://kbus.kradac.com')"
                >Bus</span
              ><br />
              <span style="font-family: poppinsLigth" @click="hrefEnlaces('')"
                >Scooter</span
              ><br />
              <span style="font-family: poppinsLigth" @click="hrefEnlaces('')"
                >Bicicleta</span
              ><br />
              <span style="font-family: poppinsLigth" @click="hrefEnlaces('')"
                >Carsharing</span
              ><br />
              <span style="font-family: poppinsLigth" @click="hrefEnlaces('')"
                >Billetera</span
              ><br />
              <span style="font-family: poppinsLigth" @click="hrefEnlaces('')"
                >Encomienda</span
              ><br />
              <span
                style="font-family: poppinsLigth"
                @click="hrefEnlaces('https://web.clipp.eco/delivery/#/')"
                >Delivery</span
              ><br />
            </v-col>
            <v-col style="text-align: initial">
              <span
                style="
                  color: rgb(56 209 179);
                  font-family: visby;
                  font-size: large;
                  font-weight: 500;
                "
                >Descárgala ahora</span
              ><br />
              <a href="https://bit.ly/ClippAnd" target="_blank"
                ><v-img
                  :src="require('../assets/play.png')"
                  height="70px"
                  width="150px"
                  contain
              /></a>

              <a href="https://bit.ly/ClippMov" target="_blank"
                ><v-img
                  :src="require('../assets/apple.png')"
                  height="70px"
                  width="150px"
                  contain
              /></a>

              <a href="https://bit.ly/ClippAppGallery" target="_blank"
                ><v-img
                  :src="require('../assets/imghuawei.png')"
                  height="70px"
                  width="150px"
                  contain
              /></a>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-divider class="dividerFooter" dark></v-divider>

      <v-card-text
        class="white--text d-flex flex-column flex-md-row flex-xl-row flex-sm-column"
        style="align-items: baseline"
        width="100%"
      >
        <v-row>
          <v-col xs="12" sm="6">
            <div style="font-family: poppinsLigth; text-align: start">
              Copyright 2022. Clipp. Todos los derechos reservados.
            </div>
          </v-col>
          <v-col xs="12" sm="6">
            <div class="d-flex justify-content-end" style="width: 100%">
              <a
                style="color: #ffffff; font-family: poppinsLigth"
                class="nav-link"
                href="#"
                >Aviso de Política de Privacidad</a
              >
              <span style="font-size: x-large"> &nbsp; | &nbsp; </span>

              <a
                style="color: #ffffff; font-family: poppinsLigth"
                class="nav-link"
                href="#"
                >Consejos de Seguridad</a
              >
              <span style="font-size: x-large"> &nbsp; | &nbsp;</span>
              <a
                style="color: #ffffff; font-family: poppinsLigth"
                class="nav-link"
                href="#"
                >Términos y Condiciones</a
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mdiFacebook } from "@mdi/js";
import { mdiTwitter } from "@mdi/js";
import { mdiInstagram } from "@mdi/js";
import { mdiLinkedin } from "@mdi/js";
export default {
  setup() {},
  data: () => ({
    iconF: mdiFacebook,
    iconT: mdiTwitter,
    iconI: mdiInstagram,
    iconLi: mdiLinkedin,
    url: process.env.VUE_APP_URL,
  }),
  created() {},

  methods: {
    hrefEnlaces(link) {
      console.log("dentro");
      if (link && link.length > 0) window.open(link, "_blank").focus();
    },
  },
};
</script>

<style lang="scss">
/* .siguenos{
    display: flex;
    justify-content: end;
    align-items: center;
} */

@font-face {
  font-family: poppinsLigth;
  src: url("../fonts/Poppins-Light.ttf") format("opentype");
}

@font-face {
  font-family: poppinsSemiBold;
  src: url("../fonts/Poppins-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: visbyRegular;
  src: url("../fonts/VisbyRoundCF-Regular.otf") format("opentype");
}

@font-face {
  font-family: Visby;
  src: url("../fonts/VisbyRoundCF-DemiBold.otf") format("opentype");
}

@font-face {
  font-family: VisbyBold;
  src: url("../fonts/VisbyRoundCF-Bold.otf") format("opentype");
}

@font-face {
  font-family: popinsRegular;
  src: url("../fonts/Poppins-Regular.ttf") format("opentype");
}
@media only screen and (max-width: 600px) {
  /* .siguenos{
    display: flex;
    justify-content: center;
    align-items: center;
} */
}

.dividerFooter {
  border-width: 1px !important;
  opacity: 1 !important;
  border-color: #37d1b3 !important;
}

.copiryng {
}

@media only screen and (max-width: 992px) {
  .copiryng {
  }
}
</style>
