<template>
  <div class="home" style="margin-top:80px">
    <Principal/>
  </div>
</template>

<script>
// @ is an alias to /src
import Principal from '@/components/Principal.vue'

export default {
  name: 'Home',
  components: {
    Principal
  }
}
</script>
