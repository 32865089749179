<template>
<div style="margin-top:80px">
  <Contacto></Contacto>
  </div>
</template>

<script>
import Contacto from "../components/Contacto.vue";

export default {
  components: {
    Contacto
  },

  created() {
    console.log('creando vista de contacto')
  },

  methods: {

  }
};
</script>

<style>

</style>
