<template>
  <v-app>
    <Barra></Barra>
    <MenuH></MenuH>
    <v-main>
      <router-view />
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import MenuH from "./components/Menu.vue";
import Footer from "./components/Footer.vue";
import Barra from "./components/Barra.vue";
import $ from "jquery";

export default {
  name: "App",
  components: {
    MenuH,
    Footer,
    Barra,
  },

  data: () => ({}),

  created() {
    $(document).ready(function () {
      document.addEventListener("touchstart", function () {}, true);
    });
  },
};
</script>

<style>
.v-system-bar {
  position: fixed;
  width: 100%;
  z-index: 1030;
}

@font-face {
  font-family: Visby;
  src: url("./fonts/VisbyRoundCF-DemiBold.otf") format("opentype");
}
</style>
