<template>
  <div style="margin-left: 5%; margin-right: 5%">
    <nav
      class="navbar navbar-expand-lg navbar-dark colorMenu fixed-top"
      id="navbar-example2"
    >
      <div class="container">
        <a
          ><v-img
            title="Clipp | Mejoramos tu mundo"
            class="imgC"
            :src="require('../assets/clipp.png')"
            max-width="100"
            @click="recargarPagina"
        /></a>
        <v-spacer></v-spacer>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse menu" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item active">
              <a
                class="nav-link active inicio"
                aria-current="page"
                @click="cerrarToogle"
                href="#inicio"
                >Inicio</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link quienesSomos"
                href="#quienesSomos"
                @click="cerrarToogle"
                >Quienes Somos</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link servicios"
                href="#servicios"
                @click="cerrarToogle"
                >Servicios</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://clipp.eco/blog/"
                target="_blank"
                id="new"
                @click="cerrarToogle"
                >Blog</a
              >
            </li>
            <!-- <li class="nav-item">
              <a
                class="nav-link"
                href="https://web.clipp.eco/ktaxicliente3.0/"
                target="_blank"
                @click="cerrarToogle"
                >Corporativo</a
              >
            </li> -->
            <li class="nav-item">
              <a class="nav-link clipper" @click="cerrarToogleC">Contactos</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link clipper"
                href="https://clipp.app/corporativo/"
                target="_blank"
                @click="cerrarToogle"
                style="color: #fff"
                >Corporativo</a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                @click="clickD"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Administración
              </a>
              <ul
                class="dropdown-menu dropdown-menu-lg-end"
                aria-labelledby="navbarDropdown"
              >
                <li
                  class="d-flex"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <a
                    class="dropdown-item"
                    href="https://web.clipp.app/ktaxicliente3.0/#solicitarTaxi"
                    target="_blank"
                    @click="cerrarToogle"
                    style="color: #fff"
                    >Taxi Corporativo Usuario</a
                  >
                </li>
                <!-- <li class="d-flex" style="padding-left: 5px; padding-right: 5px">
                  <a class="dropdown-item" href="https://web.clipp.app/delivery/#/" target="_blank" @click="cerrarToogle"
                    style="color: #fff">Clipp Delivery Usuario</a>
                </li>
                <li class="d-flex" style="padding-left: 5px; padding-right: 5px">
                  <a class="dropdown-item" href="https://web.clipp.app/clippStore/#presentationRoute" target="_blank"
                    @click="cerrarToogle" style="color: #fff">Clipp Delivery Store</a>
                </li> -->
                <li>
                  <hr class="dividerM dropdown-divider" />
                </li>
                <li
                  class="d-flex"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <!-- <v-icon left color="#fff">{{ iconAdmin }}</v-icon> -->
                  <a
                    class="dropdown-item"
                    href="https://web.clipp.app/ktaxiweb2.0/"
                    target="_blank"
                    @click="cerrarToogle"
                    style="color: #fff"
                    >Admin. Taxi Voucher</a
                  >
                </li>
                <li
                  class="d-flex"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <!-- <v-icon left color="#fff">{{ iconAdmin }}</v-icon> -->
                  <a
                    class="dropdown-item"
                    href="https://web.clipp.app/ktaxi/"
                    target="_blank"
                    @click="cerrarToogle"
                    style="color: #fff"
                    >Admin. Clipp Taxi</a
                  >
                </li>
                <li
                  class="d-flex"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <!-- <v-icon left color="#fff">{{ iconAdmin }}</v-icon> -->
                  <a
                    class="dropdown-item"
                    href="https://web.clipp.app/clippweb"
                    target="_blank"
                    @click="cerrarToogle"
                    style="color: #fff"
                    >Admin. Clipp MaaS / LaaS</a
                  >
                </li>

                <li
                  class="d-flex"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <!-- <v-icon left color="#fff">{{ iconAdmin }}</v-icon> -->
                  <a
                    class="dropdown-item"
                    href="https://maas.clipp.app"
                    target="_blank"
                    @click="cerrarToogle"
                    style="color: #fff"
                    >Admin. Clipp 3.0
                  </a>
                </li>

                <li
                  class="d-flex"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <!-- <v-icon left color="#fff">{{ iconAdmin }}</v-icon> -->
                  <a
                    class="dropdown-item"
                    href="https://web.clipp.app/ktaxiWeb3.0/"
                    target="_blank"
                    @click="cerrarToogle"
                    style="color: #fff"
                    >Admin. Clipp Taxi 3.0
                  </a>
                </li>

                <li
                  class="d-flex"
                  style="padding-left: 5px; padding-right: 5px"
                >
                  <!-- <v-icon left color="#fff">{{ iconAdmin }}</v-icon> -->
                  <a
                    class="dropdown-item"
                    href="https://lookerstudio.google.com/s/rZhrNO2mqiU"
                    target="_blank"
                    @click="cerrarToogle"
                    style="color: #fff"
                    >Estadísticas Clipp Taxi
                  </a>
                </li>
                <!-- <v-list-item v-for="(item, i) in administracion" :key="i">
                        <v-list-item-icon>
                          <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title style=" font-family: Visby;"
                            v-text="item.text" @click="abrirLink(item.link)"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item> -->
              </ul>
            </li>
          </ul>
          <!--  <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form> -->
        </div>
      </div>
    </nav>
  </div>

  <!-- <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 prueba">
                <a href="" class="navbar-brand p-0">
                    <h1 class="m-0">DGital</h1>
                   
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span class="fa fa-bars"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <div class="navbar-nav mx-auto py-0">
                        <a href="/" class="nav-item nav-link active">Home</a>
                        <a href="#home1" class="nav-item nav-link">About</a>
                        <a href="#home2" class="nav-item nav-link">Service</a>
                        <a href="/about" class="nav-item nav-link">Project</a>
                        <div class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                            <div class="dropdown-menu m-0">
                                <a href="team.html" class="dropdown-item">Our Team</a>
                                <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                                <a href="404.html" class="dropdown-item">404 Page</a>
                            </div>
                        </div>
                        <a href="contact.html" class="nav-item nav-link">Contact</a>
                    </div>
                    <a href="" class="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block">Get Started</a>
                </div>
            </nav> -->
</template>

<script>
import $ from "jquery";
import { mdiMenuRightOutline } from "@mdi/js";
import { mdiTaxi } from "@mdi/js";
import { mdiMopedElectric } from "@mdi/js";
import { mdiCart } from "@mdi/js";
import { mdiAccountLock } from "@mdi/js";
import { mdiAccount } from "@mdi/js";
export default {
  setup() {},
  data: () => ({
    //scroll: false,
    scroll: false,
    url: process.env.VUE_APP_URL,
    iconMenu: mdiMenuRightOutline,
    iconInicio: mdiAccount,
    iconTaxi: mdiTaxi,
    iconUsuario: mdiCart,
    iconStore: mdiMopedElectric,
    iconAdmin: mdiAccountLock,
    administracion: [
      {
        icon: mdiTaxi,
        text: "Taxi Voucher",
        link: "https://web.clipp.eco/ktaxiweb2.0/",
      },
      {
        icon: mdiCart,
        text: "Clipp Delivery Usuario",
        link: "https://web.clipp.eco/delivery/",
      },
      {
        icon: mdiMopedElectric,
        text: "Clipp Delivery Store",
        link: "https://web.clipp.eco/clippStore/#presentationRoute",
      },
      {
        icon: mdiAccountLock,
        text: "Administración Clipp",
        link: "https://web.clipp.eco/clippweb/",
      },
    ],
    model: 1,
  }),
  created() {
    console.log("creado menu principal -----------");
    console.log(this.$router.history);

    this.click();
    this.clickb();
    window.addEventListener("scroll", this.handleScroll);
    var g = this;
    $(document).ready(function () {
      console.log("inicio el codigo");
      g.click();
    });
  },
  mounted() {
    console.log("montado");
    if (this.$router.history.current.name == "Contactos") {
      console.log("si en contacto");
      this.click();
      $("ul li a").removeClass("active");
      $(".clipper").addClass("active");
      this.scroll = true;
    } else {
      //this.scroll = false;
      this.scroll = false;
    }
  },
  methods: {
    cerrarToogle: function () {
      //this.click();
      console.log("ceradnp");
      let element = document.getElementsByClassName("navbar-toggler")[0];
      if (element.getAttribute("aria-expanded") == "true") {
        element.click();
      }
      this.$router.replace({ path: "/" }).catch(() => {});
      //window.lacation = window.lacation.href;
    },
    cerrarToogleC: function () {
      this.click();
      console.log("ceradnp");
      let element = document.getElementsByClassName("navbar-toggler")[0];
      if (element.getAttribute("aria-expanded") == "true") {
        element.click();
      }
      this.$router.replace({ path: "/" }).catch(() => {});
      this.$router.push("/contactos");
      this.click();
      this.scroll = true;
    },
    clicka: function () {
      console.log("dentro de clic a");
      var a = this;
      /*  console.log(this.$router.history);
      console.log(this.$router.history.current.name); */
      $("ul li").removeClass("active");
      $("ul li").click(function () {
        /*  console.log("clci en a");
        console.log(a.$router.history.current.name); */
        // if already any element in active status remove it
        if (a.$router.history.current.name == "Contactos") {
          //console.log("ingreso");
          $("ul li").removeClass("active");
          // add active status to this one only
          $(this).addClass("active");
        } else {
          $("ul li").removeClass("active");
        }
      });
    },
    click: function () {
      console.log("dentro de clic a");

      $("ul li a").click(function () {
        // if already any element in active status remove it

        console.log("ingreso click a");
        $("ul li a").removeClass("active");
        // add active status to this one only
        $(this).addClass("active");
      });
    },
    clickb: function () {
      console.log("dentro de clic a");

      $("ul li").click(function () {
        // if already any element in active status remove it

        console.log("ingreso click b");
        $("ul li a").removeClass("active");
        // add active status to this one only
        $(this).addClass("active");
      });
    },
    handleScroll() {
      //console.log(event);
      if (this.scroll) {
        var scrollPos = $(document).scrollTop();
        $("li a").each(function () {
          //console.log("dentro de menu a");
          var currLink = $(this);
          if (currLink && currLink.length > 0) {
            /*  console.log(currLink);
            console.log(currLink[0]); */
            var hash = $(currLink.attr("target"));
            if (!hash.prevObject) {
              var refElement = $(currLink.attr("href"));
              //console.log(refElement);
              if (refElement && refElement.length > 0)
                if (
                  refElement.position().top &&
                  parseInt(refElement.position().top) <= scrollPos &&
                  parseInt(refElement.position().top) +
                    parseInt(refElement.height()) >
                    scrollPos
                ) {
                  $("li a").removeClass("active");
                  currLink.addClass("active");
                } else {
                  currLink.removeClass("active");
                }
            }
          }
        });
      } else {
        //$("li a").removeClass("active");
      }

      // Any code to be executed when the window is scrolled
    },
    clickD() {
      console.log("clci en sub menu");
      $("ul li a").removeClass("active");
    },
    abrirLink(link) {
      console.log(link);
      $("ul li a").removeClass("active");
      if (link && link.length > 0) window.open(link, "_blank").focus();
    },

    recargarPagina() {
      console.log("dentro de recargar pagina");
      window.location.replace(this.url);
    },
  },
  watch: {},
};
</script>

<style>
/* #header {
    padding: 30px 0;
    height: 92px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;
}

.prueba{
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    margin-top: 0px;
    background: #fff !important;
    -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    padding: 0;
}

.navbar {
    position: absolute;
} */

.fixed-top {
  position: fixed;
  top: 20;
  right: 0;
  left: 0;
  margin-top: 40px;
}

.colorMenu {
  background-color: #106dfd !important;
  font-family: Visby;
  font-size: large;
}

#navbarSupportedContent {
  justify-content: flex-end;
  flex-grow: 0;
}

li:hover {
  background-color: #37d1b3;
  border-radius: 20px;
}

.menu ul .nav-item .nav-link {
  border-radius: 20px;
  /* margin-left: 10px;
  margin-right: 10px; */
  padding-left: 25px;
  padding-right: 25px;
}

.menu ul li a ul li a {
  border-radius: 20px;
}

li a {
  color: white;
}

li .active {
  background-color: #37d1b3;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.collapse ul li {
  color: #ffffff;
}

.collapse ul.navbar-nav > li > a {
  color: #ffffff;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 0;
  justify-content: space-between;
  /* align-items: center; */
}

.imgC {
  margin-left: 20px;
}

.menu {
  margin-right: 40px;
}

@font-face {
  font-family: Visby;
  src: url("../fonts/VisbyRoundCF-DemiBold.otf") format("opentype");
}

.dropdown-item {
  border-radius: 20px;
  font-family: Visby;
  font-size: large;
}

.dropdown-menu a:hover {
  background-color: #37d1b3;
  color: white;
}

.dropdown-menu {
  padding-left: 5px !important;
}

.navbar-nav .dropdown-menu {
  background-color: #1857f0;
  --bs-dropdown-border-color: rgb(0 0 0 / 6%);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dividerM {
  color: #37d1b3;
  border-top: 1px solid !important;
}

@media only screen and (max-width: 600px) {
  /* #navbarSupportedContent {
  justify-content: flex-end;
  flex: auto;
} */

  .imgC {
    margin-left: 20px;
  }

  .fixed-top {
    position: fixed;
    top: 20;
    right: 0;
    left: 0;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 750px) {
  /* #navbarSupportedContent {
  justify-content: flex-end;
  flex: auto;
} */

  .imgC {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .imgC {
  }
}

@media only screen and (min-width: 768px) {
  .imgC {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .imgC {
    margin-left: 80px;
  }
}
</style>
